import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import classNames from "@/base/lib/class-names.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";
import Message from "@/base/components/message/index.js";
import Text from "@/base/components/text/index.js";

import app from "@/app/app.js";

import linkStyles from "@/base/components/link/styles.module.css";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    pagesBySlug: state.pages.pagesBySlug,
});

const PopupPageContainer = (props) => {
    const { slug } = props;

    const store = useSelector(storeSelector);
    const page = store.pagesBySlug[slug];

    useEffect(() => {
        if (!slug) {
            return;
        }

        app.actions.common.pages.loadPageBySlug({ slug });
    }, []);

    const renderContent = () => {
        if (!page) {
            return null;
        }

        if (page.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (page.error) {
            return (
                <Message>
                    {page.error}
                </Message>
            );
        }

        const contentClassName = classNames({
            [styles.content]: true,
            [linkStyles.contentWithLink]: true,
        });

        return (
            <Text className={contentClassName}>
                {page.page?.content}
            </Text>
        );
    };

    return (
        <PopupWindow
            title={page?.page.title || ""}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupPageContainer.defaultProps = {
    slug: "",
    onClose: () => { },
};

export default PopupPageContainer;
