import React, { useRef } from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const FivesyKeyboardKey = (props) => {
    const keyRef = useRef(null);

    const getLabel = () => {
        let status = "";

        if (props.isAbsent) {
            status = "absent";
        } else if (props.isPresent) {
            status = "present";
        } else if (props.isCorrect) {
            status = "correct";
        }

        return `${props.value} ${status}`;
    };

    const onClick = () => {
        if (keyRef?.current) {
            keyRef.current.blur();
        }

        if (props.isDisabled) {
            return;
        }

        props.onClick();
    };

    if (!props.value) {
        return null;
    }

    const keyClassName = classNames({
        [styles.key]: true,
        [styles.keyControl]: props.isControl,
        [styles.keyAbsent]: props.isAbsent,
        [styles.keyPresent]: props.isPresent,
        [styles.keyCorrect]: props.isCorrect,
        [styles.keyDesktop]: props.isDesktop,
        [styles.keyDisabled]: props.isDisabled,
    });

    // NOTE: onKeyPress conflicts with keyboard
    return (
        <button
            ref={keyRef}
            className={keyClassName}
            onClick={onClick}
            onKeyPress={() => { }}
            tabIndex="-1"
            type="button"
            aria-label={getLabel()}
        >
            {props.value}
        </button>
    );
};

FivesyKeyboardKey.defaultProps = {
    value: "",
    onClick: () => { },
    isAbsent: false,
    isPresent: false,
    isCorrect: false,
    isControl: false,
    isDesktop: false,
    isDisabled: false,
};

export default FivesyKeyboardKey;
