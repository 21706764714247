const loadFirstClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setClosedPollsLoading());

        const page = 0;
        const onlyFirst = params.onlyFirst || false;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const preloadFirstClosedPolls = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const polls = state.polls.closedPolls;

        if (polls.length > 1) {
            return;
        }

        dispatch(actions.polls.setClosedPollsLoading());

        const page = 0;
        const onlyFirst = false;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const loadClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setClosedPollsLoading());

        const page = params.page || 0;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.addClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const loadMoreClosedPolls = (services) => {
    return (dispatch, getState) => {
        const state = getState();

        const polls = state.polls.closedPolls;
        const page = state.polls.page || 0;

        if (page === 0 && polls.length < 10) {
            dispatch(loadFirstClosedPolls(services, {
                onlyFirst: false,
            }));
            return;
        }

        dispatch(loadClosedPolls(services, {
            page: page + 1,
        }));
    };
};

export default {
    loadFirstClosedPolls,
    preloadFirstClosedPolls,
    loadClosedPolls,
    loadMoreClosedPolls,
};
