import React, { useRef, useEffect, useState } from "react";

import classNames from "@/base/lib/class-names.js";

import "./styles.css";
import styles from "./styles.module.css";


const Video = (props) => {
    const videoRef = useRef(null);
    const trackRef = useRef(null);

    const [isTrackLoaded, setIsTrackLoaded] = useState(false);

    const loadTrack = (track) => {
        const xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = () => {
            if (xhttp.readyState === 4
                && xhttp.status === 200
                && xhttp.responseText) {
                const blob = new Blob([xhttp.responseText], {
                    type: "text/vtt",
                });
                track.setAttribute("src", URL.createObjectURL(blob));
            }
        };

        xhttp.open("GET", track.src, true);
        xhttp.send();
    };

    useEffect(() => {
        if (trackRef?.current && !isTrackLoaded) {
            setIsTrackLoaded(true);
            loadTrack(trackRef.current);
        }
    }, []);

    useEffect(() => {
        if (!props.canPlay && videoRef.current) {
            videoRef.current.pause();
        }
    }, [props.canPlay]);

    const videoStyle = {
        height: props.videoHeight || "auto",
    };

    const videoClassName = classNames({
        [styles.video]: true,
        [styles.videoPoster]: props.withPosterCover,
    });

    // NOTE: picture-in-picture cause minor issues with video
    //       firefox is not supporting disablePictureInPicture property
    return (
        <video
            ref={videoRef}
            className={videoClassName}
            poster={props.poster}
            style={videoStyle}
            onPlay={() => {
                props.onPlayStart(props.src, props.videoId);
            }}
            onEnded={() => {
                props.onPlayEnd(props.src, props.videoId);
            }}
            onPause={() => {
                props.onPause(props.src, props.videoId);
            }}
            preload="none"
            controls
            disablePictureInPicture
        >
            <source
                src={props.src}
                type={props.type}
            />
            <track
                ref={trackRef}
                src={props.captionSrc}
                kind="captions"
                srcLang="en"
                label="English"
            />
            Your browser does not support the video tag.
        </video>
    );
};

Video.defaultProps = {
    videoId: -1,
    src: "",
    type: "",
    poster: "",
    videoHeight: "auto",
    canPlay: true,
    withPosterCover: true,
    captionSrc: null,
    onPlayStart: () => { },
    onPlayEnd: () => { },
    onPause: () => { },
};

export default Video;
