import date from "@/base/lib/date.js";


const head = (arr) => {
    if (arr && arr.length > 0) {
        return arr[0];
    }

    return null;
};

const last = (arr) => {
    if (arr && arr.length > 0) {
        return arr[arr.length - 1];
    }

    return null;
};

const penultimate = (arr) => {
    if (arr && arr.length > 1) {
        return arr[arr.length - 2];
    }

    return null;
};

/* --- */

const findByFieldName = (arr, fieldName, value) => {
    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i += 1) {
            const obj = arr[i] || {};

            if (obj[fieldName] === value) {
                return obj;
            }
        }
    }

    return null;
};

const findOneById = (arr, objId) => {
    if (arr) {
        for (let i = 0; i < arr.length; i += 1) {
            const obj = arr[i];

            if (obj.id === objId) {
                return obj;
            }
        }
    }

    return null;
};

const findOneByName = (arr, objName) => {
    if (arr) {
        for (let i = 0; i < arr.length; i += 1) {
            const obj = arr[i];

            if (obj.name === objName) {
                return obj;
            }
        }
    }

    return null;
};

const findOneByValue = (arr, value) => {
    return findByFieldName(arr, "value", value);
};

const findOneByType = (arr, value) => {
    return findByFieldName(arr, "type", value);
};

/* --- */

const findAllByIds = (arr, ids) => {
    const result = [];

    if (!arr) {
        return result;
    }

    for (let i = 0; i < arr.length; i += 1) {
        const obj = arr[i];

        if (ids.indexOf(obj.id) !== -1) {
            result.push(obj);
        }
    }

    return result;
};

/* --- */

const filterCallbackNonEmptyString = (val) => {
    if (typeof val === "string") {
        const str = val.trim();
        return str;
    }

    return val;
};

/* --- */

const sortByNumberASC = (arr, field) => {
    if (!Array.isArray(arr)) {
        return arr;
    }

    return [...arr].sort((a, b) => {
        const aVal = a?.[field] || 0;
        const bVal = b?.[field] || 0;
        return aVal - bVal;
    });
};

const sortByNumberDESC = (arr, field) => {
    if (!Array.isArray(arr)) {
        return arr;
    }

    return [...arr].sort((a, b) => {
        const aVal = a?.[field] || 0;
        const bVal = b?.[field] || 0;
        return bVal - aVal;
    });
};

const sortByDateASC = (arr, field) => {
    if (!Array.isArray(arr)) {
        return arr;
    }

    return [...arr].sort((a, b) => {
        const aVal = a?.[field] || 0;
        const bVal = b?.[field] || 0;

        const d0 = date.newDate(aVal);
        const d1 = date.newDate(bVal);

        return d0.getTime() - d1.getTime();
    });
};

const sortByDateDESC = (arr, field) => {
    if (!Array.isArray(arr)) {
        return arr;
    }

    return [...arr].sort((a, b) => {
        const aVal = a?.[field] || 0;
        const bVal = b?.[field] || 0;

        const d0 = date.newDate(aVal);
        const d1 = date.newDate(bVal);

        return d1.getTime() - d0.getTime();
    });
};

const sortByFieldATOZ = (arr, field) => {
    if (!Array.isArray(arr)) {
        return arr;
    }

    return [...arr].sort((a, b) => {
        const aVal = a?.[field] || "";
        const bVal = b?.[field] || "";
        return aVal.localeCompare(bVal);
    });
};

const sortByFieldZTOA = (arr, field) => {
    if (!Array.isArray(arr)) {
        return arr;
    }

    return [...arr].sort((a, b) => {
        const aVal = a?.[field] || "";
        const bVal = b?.[field] || "";
        return bVal.localeCompare(aVal);
    });
};

/* --- */

export default {
    head,
    last,
    penultimate,

    findByFieldName,
    findOneById,
    findOneByName,
    findOneByValue,
    findOneByType,

    findAllByIds,

    filterCallbackNonEmptyString,

    sortByNumberASC,
    sortByNumberDESC,
    sortByFieldATOZ,
    sortByFieldZTOA,
    sortByDateASC,
    sortByDateDESC,
};
