import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import date from "@/base/lib/date.js";
import classNames from "@/base/lib/class-names.js";

import IconScoresVertical from "@/base/icons/scores-vertical/index.js";
import IconJuiceCup from "@/base/icons/juice-cup/index.js";
import IconCopyWithArrow from "@/base/icons/copy-with-arrow/index.js";

import SectionStudentV2 from "@/base/business/section-student-v2/index.js";
import TabsV2 from "@/base/components/tabs-v2/index.js";
import ChartBarCustom, { ChartBarCustomWrapper } from "@/base/components/chart-bar-custom/index.js";
import TooltipInfoWithIcon from "@/base/components/tooltip-info-with-icon/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import Message from "@/base/components/message/index.js";

import Control from "./control.js";
import styles from "./styles.module.css";


const CHART_MIN_VALUE = 2;

const TAB_VALUES = {
    oneWeek: "1-week",
    twoWeeks: "2-weeks",
    oneMonth: "1-month",
    threeMonths: "3-months",
};

const INIT_TAB = TAB_VALUES.oneWeek;

const DailyJuiceQuizResults = (props) => {
    const [selectedTab, setSelectedTab] = useState(() => INIT_TAB);
    const [isChartBarTooltipsVisible, setIsChartBarTooltipsVisible] = useState(false);

    /* --- */

    const results = useMemo(() => {
        if (TAB_VALUES.oneWeek === selectedTab) {
            return props.data.weeks[1] || null;
        }

        if (TAB_VALUES.twoWeeks === selectedTab) {
            return props.data.weeks[2] || null;
        }

        if (TAB_VALUES.oneMonth === selectedTab) {
            return props.data.months[1] || null;
        }

        if (TAB_VALUES.threeMonths === selectedTab) {
            return props.data.months[3] || null;
        }

        return null;
    }, [props.data, selectedTab]);

    /* --- */

    const isMinValue = useCallback((value) => {
        if (!value) {
            return true;
        }

        return value <= CHART_MIN_VALUE;
    }, []);

    /* --- */

    const onChangeTab = useCallback((tab) => {
        setSelectedTab(tab);

        if (TAB_VALUES.oneWeek === tab) {
            props.onChangeTab({
                type: "weeks",
                amount: 1,
            });
            return;
        }

        if (TAB_VALUES.twoWeeks === tab) {
            props.onChangeTab({
                type: "weeks",
                amount: 2,
            });
            return;
        }

        if (TAB_VALUES.oneMonth === tab) {
            props.onChangeTab({
                type: "months",
                amount: 1,
            });
            return;
        }

        if (TAB_VALUES.threeMonths === tab) {
            props.onChangeTab({
                type: "months",
                amount: 3,
            });
        }
    }, [props.onChangeTab]);

    /* --- */

    const getCorrectAnswersPercent = useCallback(() => {
        const data = results?.data?.data || [];

        let totalScore = 0;
        let maxScore = 0;

        for (let i = 0; i < data.length; i += 1) {
            const d = data[i];

            totalScore += d.value || 0;

            if (d.hasQuizzes && d.hasAttempts) {
                maxScore += 100;
            }
        }

        if (!maxScore) {
            return 0;
        }

        const totalPercent = (totalScore * 100) / maxScore;

        if (Number.isNaN(totalPercent)) {
            return 0;
        }

        return totalPercent.toFixed(1);
    }, [results]);

    const getBarLabel = useCallback((barDate, index) => {
        if (selectedTab === TAB_VALUES.oneMonth) {
            if (index % 5 === 0) {
                return date.tryFormatDateUTC(barDate, date.getMonthDayFromDate);
            }

            return "";
        }

        return date.tryFormatDateUTC(barDate, date.getMonthDayFromDate);
    }, [selectedTab]);

    const getBarDatasetValue = useCallback((value, hasAttempts) => {
        if (!hasAttempts) {
            return 0;
        }

        if (isMinValue(value)) {
            return CHART_MIN_VALUE;
        }

        return value;
    }, []);

    const getBarValue = useCallback((value, isLabelSmall) => {
        let barValue = `${value}%`;

        if (selectedTab === TAB_VALUES.oneMonth || isMinValue(value)) {
            barValue = "";
        }

        const isOnTop = value <= 10;

        return {
            className: classNames({
                [styles.chartValue]: true,
                [styles.chartValueOnBar]: !isOnTop,
                [styles.chartValueSmall]: isLabelSmall,
                [styles.chartValueSmallExtraSmall]: props.isMobile && isLabelSmall,
            }),
            value: barValue,
            isOnTop,
        };
    }, [selectedTab, props.isMobile]);

    const getBarTooltip = useCallback((value, hasAttempts, hasQuizzes) => {
        let content = "";

        if (!hasQuizzes) {
            content = "NA";
        } else if (!hasAttempts) {
            content = "No Attempts";
        } else if (value === 0) {
            content = "0%";
        }

        return {
            contentClassName: styles.chartBarTooltipContent,
            content,
            isVisible: isChartBarTooltipsVisible,
        };
    }, [isChartBarTooltipsVisible]);

    const getBarWidth = useCallback(() => {
        let barWidth = 5;

        if (selectedTab === TAB_VALUES.twoWeeks) {
            barWidth = 2.5;
        } else if (selectedTab === TAB_VALUES.oneMonth) {
            barWidth = 1;
        } else if (selectedTab === TAB_VALUES.threeMonths) {
            barWidth = 6;
        }

        if (props.isMobile) {
            barWidth *= 0.75;
        }

        return barWidth;
    }, [selectedTab, props.isMobile]);

    /* --- */

    useEffect(() => {
        onChangeTab(INIT_TAB);
    }, []);

    /* --- */

    const renderTabs = () => {
        const tabs = [
            { value: TAB_VALUES.oneWeek, label: "1 Week" },
            { value: TAB_VALUES.twoWeeks, label: "2 Weeks" },
            { value: TAB_VALUES.oneMonth, label: "1 Month" },
            { value: TAB_VALUES.threeMonths, label: "3 Months" },
        ];

        return (
            <div className={styles.tabs}>
                <TabsV2
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onChange={onChangeTab}
                />
            </div>
        );
    };

    const renderInfoDates = () => {
        const dFrom = results?.data?.dates?.from || "";
        const dTo = results?.data?.dates?.to || "";

        if (!dFrom || !dTo) {
            return null;
        }

        const dateFrom = date.tryFormatDateUTC(dFrom, date.formatMonthDayYearDate);
        const dateTo = date.tryFormatDateUTC(dTo, date.formatMonthDayYearDate);

        if (!dateFrom || !dateTo) {
            return null;
        }

        return (
            <div className={styles.infoLabel2}>
                {`${dateFrom} - ${dateTo}`}
            </div>
        );
    };

    const renderInfo = () => {
        const correctAnswers = getCorrectAnswersPercent();

        return (
            <div className={styles.infoContainer}>
                <div className={styles.info}>
                    <div className={styles.infoLabel}>
                        {`% Answers Correct: ${correctAnswers}%`}
                    </div>
                    <TooltipInfoWithIcon
                        tooltip={{
                            message: "Your average score is calculated based on quiz responses for Daily Juice and assigned articles",
                        }}
                        onHover={setIsChartBarTooltipsVisible}
                        isSky
                    />
                </div>
                {renderInfoDates()}
            </div>
        );
    };

    const renderChart = () => {
        const chartData = results?.data?.data || [];
        const isLabelSmall = chartData.length >= 8;

        const data = (chartData || []).map((d, index) => {
            const { value, hasAttempts, hasQuizzes } = d;

            return {
                dataset: [{
                    color: "var(--chart-bar-color-1)",
                    value: getBarDatasetValue(value, hasAttempts),
                }],
                label: getBarLabel(d.date, index),
                barTooltip: getBarTooltip(value, hasAttempts, hasQuizzes),
                barValue: getBarValue(value, isLabelSmall),
            };
        });

        const barLabelClassName = classNames({
            [styles.chartLabel]: true,
            [styles.chartLabelSmall]: isLabelSmall,
            [styles.chartLabelExtraSmall]: props.isMobile && isLabelSmall,
        });

        return (
            <ChartBarCustomWrapper>
                <ChartBarCustom
                    barLabelClassName={barLabelClassName}
                    data={data}
                    barWidth={getBarWidth()}
                    maxAxisX={5}
                    minX={100}
                    onLabelHover={setIsChartBarTooltipsVisible}
                    onAxisXHover={setIsChartBarTooltipsVisible}
                    isLabelsVertical={false}
                    withLabelEllipsis={false}
                />
            </ChartBarCustomWrapper>
        );
    };

    const renderChartContent = () => {
        const isLoaded = results?.isLoaded || false;
        const error = results?.error || "";

        if (!isLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (error) {
            return (
                <Message>
                    {error}
                </Message>
            );
        }

        return (
            <>
                {renderInfo()}
                {renderChart()}
            </>
        );
    };

    const renderControls = () => {
        return (
            <div className={styles.controlsContainer}>
                <div className={styles.controls}>
                    <div className={styles.controlsTitle}>
                        View more
                    </div>
                    <div className={styles.controlsButtons}>
                        <Control
                            title="Daily Juices"
                            icon={IconJuiceCup}
                            onClick={props.onGoToDailyJuices}
                        />
                        <Control
                            title="Assignments"
                            icon={IconCopyWithArrow}
                            onClick={props.onGoToAssignments}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <>
                {renderTabs()}
                {renderChartContent()}
                {renderControls()}
            </>
        );
    };

    return (
        <SectionStudentV2
            title="Quiz results"
            icon={<IconScoresVertical isOrange />}
            titleBottomBorder={false}
        >
            {renderContent()}
        </SectionStudentV2>
    );
};

DailyJuiceQuizResults.defaultProps = {
    data: {
        weeks: {},
        months: {},
    },
    onChangeTab: () => { },
    onGoToDailyJuices: () => { },
    onGoToAssignments: () => { },
    isMobile: false,
};

export default DailyJuiceQuizResults;
