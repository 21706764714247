export default (apiURL, methods) => {
    return {
        setPollAnswer(params = {}) {
            // NOTE: action=set_poll_answer
            const url = `${apiURL}/polls/daily-juices`;

            return methods.post(url, {
                session_id: params.session,
                juice_id: params.juiceId,
                poll_id: params.pollId,
                answer_id: params.answerId,
            });
        },

        getAllClosedPolls(params = {}) {
            // NOTE: action=get_all_closed_polls
            const url = `${apiURL}/polls/closed`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
                only_first: params.onlyFirst ? 1 : 0,
            });
        },
    };
};
