import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    scoreReportByClassId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENTS_SCORE_REPORT_LOADING: {
            const {
                classId,
                dateFrom,
                dateTo,
            } = action.payload;

            const scoreReportByClassId = { ...state.scoreReportByClassId };
            const prevByClass = scoreReportByClassId?.[classId] || {};

            const range = `${dateFrom}-${dateTo}`;
            const prevByRange = prevByClass[range] || {};

            prevByClass[range] = {
                ...prevByRange,
                error: "",
                isLoading: true,
            };

            return {
                ...state,
                scoreReportByClassId: {
                    ...scoreReportByClassId,
                    [classId]: prevByClass,
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_SCORE_REPORT: {
            const {
                students,
                error,
                summary,
                classId,
                dateFrom,
                dateTo,
            } = action.payload;

            const scoreReportByClassId = { ...state.scoreReportByClassId };
            const prevByClass = scoreReportByClassId?.[classId] || {};

            const range = `${dateFrom}-${dateTo}`;

            prevByClass[range] = {
                students,
                summary,
                error,
                isLoading: false,
                isLoaded: true,
            };

            return {
                ...state,
                scoreReportByClassId: {
                    ...scoreReportByClassId,
                    [classId]: prevByClass,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
