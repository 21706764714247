import React, { useEffect, useState } from "react";

import text from "@/base/text/index.js";

import IconGraduationCapEmpty from "@/base/icons/graduation-cap-empty/index.js";
import IconInformation from "@/base/icons/information/index.js";
import IconScoresVertical from "@/base/icons/scores-vertical/index.js";

import ButtonCircle from "@/base/components/button-circle/index.js";
import Message from "@/base/components/message/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";
import SelectCustom from "@/base/components/select-custom/index.js";

import PopupFivesyHowto from "@/base/components/popup-fivesy-howto/index.js";
import PopupFivesyResult from "@/base/components/popup-fivesy-result/index.js";
import Fivesy from "@/base/components/fivesy/index.js";

import styles from "./styles.module.css";


const PopupFivesy = (props) => {
    const [isVisibleInfoPopup, setIsVisibleInfoPopup] = useState(false);
    const [isVisibleResultPopup, setIsVisibleResultPopup] = useState(false);

    /* --- */

    const onShowResultPopup = () => {
        const { stats } = props;

        if (stats.isVisible && stats.onLoad) {
            stats.onLoad();
        }

        setIsVisibleResultPopup(true);
    };

    /* --- */

    useEffect(() => {
        if (props.showInfo) {
            setIsVisibleInfoPopup(true);
        }
    }, [props.showInfo]);

    useEffect(() => {
        if (props.showResult) {
            onShowResultPopup();
        }
    }, [props.showResult]);

    /* --- */

    const renderHowToPopup = () => {
        if (!isVisibleInfoPopup) {
            return null;
        }

        return (
            <PopupFivesyHowto
                onClose={() => {
                    setIsVisibleInfoPopup(false);
                }}
            />
        );
    };

    const renderResultPopup = () => {
        if (!isVisibleResultPopup) {
            return null;
        }

        return (
            <PopupFivesyResult
                numberOfGuesses={props.prevWords.length}
                stats={props.stats}
                nextDate={props.nextDate}
                vocabularyWord={props.vocabularyWord}
                audio={props.audio}
                audioState={props.audioState}
                onClose={() => {
                    setIsVisibleResultPopup(false);
                }}
                isFinished={props.isFinished}
                isSuccess={props.isSuccess}
            />
        );
    };

    const renderRightControl = () => {
        const iconInfo = (
            <IconInformation
                title="How to play"
                isBlack
            />
        );

        const iconResult = (
            <IconScoresVertical
                title="Game Result"
                isBlack
            />
        );

        return (
            <>
                <ButtonCircle
                    icon={iconInfo}
                    onClick={() => {
                        setIsVisibleInfoPopup(true);
                    }}
                />
                <ButtonCircle
                    icon={iconResult}
                    onClick={onShowResultPopup}
                    disabled={props.isResultsButtonDisabled}
                />
            </>
        );
    };

    const renderGradesSelector = () => {
        if (!props.selectedGrade) {
            return null;
        }

        const icon = (
            <IconGraduationCapEmpty isBlack />
        );

        return (
            <div className={styles.gradesSelectorContainer}>
                <div className={styles.gradesSelector}>
                    <SelectCustom
                        selected={props.selectedGrade}
                        options={props.grades}
                        icon={icon}
                        onSelect={props.onGradeChange}
                        withBorder={false}
                        withScroll={false}
                        disabled={props.isLoading || props.isChecking}
                    />
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (props.wordError) {
            return (
                <Message>
                    {props.wordError}
                </Message>
            );
        }

        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!props.word) {
            return (
                <Message>
                    {text.error}
                </Message>
            );
        }

        const word = props.word?.word || "";

        return (
            <>
                {renderGradesSelector()}

                <Fivesy
                    key={word}
                    word={word}
                    currentWord={props.currentWord}
                    maxNumberOfAttempts={props.maxNumberOfAttempts}
                    prevWords={props.prevWords}
                    nextDate={props.nextDate}
                    message={props.isChecking ? "Checking ..." : ""}
                    error={props.error}
                    onLetterChange={props.onLetterChange}
                    onGuess={(value) => {
                        props.onGuess({
                            ...value,
                            wordId: props.word?.id,
                        });
                    }}
                    isFlipping={props.isFlipping}
                    isFinished={props.isFinished}
                    isDisabledKeyboard={props.isChecking}
                />
            </>
        );
    };

    return (
        <>
            {renderHowToPopup()}
            {renderResultPopup()}

            <PopupWindow
                title="Fivesy"
                rightControl={renderRightControl()}
                withScrollRef={props.contentRef}
                onClose={props.onClose}
                isTitleCentered
            >
                {renderContent()}
            </PopupWindow>
        </>
    );
};

PopupFivesy.defaultProps = {
    contentRef: null,
    selectedGrade: "",
    currentWord: "",
    maxNumberOfAttempts: 6,
    words: [],
    prevWords: [],
    dictionary: [],
    nextDate: null,
    vocabularyWord: null,
    audio: null,
    audioState: null,
    wordError: "",
    error: "",
    stats: {
        data: null,
        error: "",
        onLoad: () => { },
        isVisible: false,
        isLoading: false,
    },
    onGradeChange: () => { },
    onLetterChange: () => { },
    onGuess: () => { },
    onClose: () => { },
    isChecking: false,
    isFlipping: false,
    isFinished: false,
    isResultsButtonDisabled: false,
    isSuccess: false,
    isLoading: false,
    showInfo: false,
    showResult: false,
};

export default PopupFivesy;
