import text from "@/base/text/index.js";


const loadLastDailyJuice = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentDailyJuices.setLastDailyJuiceLoading());

        const state = getState();

        const res = await api.dailyJuices.getLastJuice({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.juice || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentDailyJuices.setLastDailyJuice({
            data,
            error,
        }));
    };
};

export default {
    loadLastDailyJuice,
};
