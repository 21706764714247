import text from "@/base/text/index.js";


const loadStoryById = (services, params = {}) => {
    const { api, actions } = services;
    const { storyId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.juiceStories.getStoryByStudent({
            session: state.user.session,
            storyId,
        });

        let story = null;
        let error = "";

        if (res.ok) {
            story = res.story || null;
            dispatch(actions.juiceStories.setStory({ story }));
        } else {
            error = res.error || text.error;
        }

        return {
            story,
            error,
        };
    };
};

export default {
    loadStoryById,
};
