import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import DailyJuiceStory from "@/base/project/daily-juice-story.js";
import DailyJuice from "@/base/project/daily-juice.js";

import classNames from "@/base/lib/class-names.js";
import date from "@/base/lib/date.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import DivButton from "@/base/components/div-button/index.js";
import MessageErrorWithTryAgain from "@/base/components/message-error-with-try-again/index.js";

import styles from "./styles.module.css";
import "./styles.css";


const SLIDE_INTERVAL_MS = 4000;

const DailyJuiceCarousel = (props) => {
    const carouselRef = useRef(null);

    const [currentIndicatorIndex, setCurrentIndicatorIndex] = useState(0);

    /* --- */

    const onSlide = (index) => {
        setCurrentIndicatorIndex(index);

        if (carouselRef.current?.resetAutoPlay) {
            carouselRef.current.resetAutoPlay();
        }
    };

    /* --- */

    const getStories = () => {
        if (!props.stories.length) {
            return [];
        }

        const stories = DailyJuice.getStudentStories(props.stories);

        return stories.map((story) => {
            const storyId = story.ID || null;

            return {
                id: storyId,
                featuredImage: story.featuredImage,
                backgroundImage: story.backgroundImage,
                title: story.title,
            };
        });
    };

    /* --- */

    const renderStories = () => {
        const stories = getStories();

        return stories.map((item) => {
            const storyImage = DailyJuiceStory.getMaxSizeImage(item?.featuredImage);

            const slideStyle = {};

            if (storyImage) {
                slideStyle.backgroundImage = `url(${storyImage.src})`;
            }

            return (
                <div
                    key={item.id}
                    className={styles.slide}
                    style={slideStyle}
                >
                    <div className={styles.gradientLayout}>
                        <div className={styles.date}>
                            {date.tryFormatDate(props.juiceDate, date.formatDayDate)}
                        </div>
                        <div className={styles.title}>
                            {item.title}
                        </div>
                        <Link
                            to={`/daily-juices/${props.juiceId}`}
                            className={styles.juiceButton}
                        >
                            Open daily juice
                        </Link>
                    </div>
                </div>
            );
        });
    };

    const renderProgress = (isSelected) => {
        if (!isSelected) {
            return null;
        }

        const progressStyle = {
            animationDuration: `${SLIDE_INTERVAL_MS}ms`,
        };

        return (
            <div
                style={progressStyle}
                className={styles.indicatorProgress}
            />
        );
    };

    const renderIndicator = (onClick, isSelected, index) => {
        const indicatorClassName = classNames({
            [styles.carouselIndicator]: true,
            [styles.pastIndicator]: index < currentIndicatorIndex,
            [styles.selectedIndicator]: isSelected,
        });

        return (
            <DivButton
                onClick={onClick}
                className={indicatorClassName}
            >
                {renderProgress(isSelected)}
            </DivButton>
        );
    };

    if (props.isLoading) {
        return (
            <div className={styles.containerCentered}>
                <RequestLoader />
            </div>
        );
    }

    if (props.error) {
        return (
            <div className={styles.containerCentered}>
                <MessageErrorWithTryAgain
                    onTryAgain={props.onTryAgain}
                >
                    {props.error}
                </MessageErrorWithTryAgain>
            </div>
        );
    }

    return (
        <div
            className={styles.container}
            data-comment="section-carousel"
        >
            <Carousel
                ref={carouselRef}
                interval={SLIDE_INTERVAL_MS}
                renderIndicator={renderIndicator}
                onChange={onSlide}
                swipeable
                autoPlay
                infiniteLoop
                emulateTouch
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                stopOnHover={false}
                useKeyboardArrows={false}
            >
                {renderStories()}
            </Carousel>
        </div>
    );
};

DailyJuiceCarousel.defaultProps = {
    juice: null,
    juiceDate: "",
    error: "",
    onTryAgain: () => { },
    isLoading: false,
};

export default DailyJuiceCarousel;
