const loadStateStandards = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { subdivision } = state.user.user;

        const res = await api.standards.getStandardsByState({
            state: subdivision,
        });

        if (res.ok) {
            dispatch(actions.standards.setStateStandardsByState({
                stateName: subdivision,
                standards: res.standards,
            }));
        }
    };
};

export default {
    loadStateStandards,
};
