import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import Countdown from "@/base/components/countdown/index.js";

import utils from "./utils.js";

import FivesyBoard from "./board.js";
import FivesyKeyboard from "./keyboard.js";

import styles from "./styles.module.css";


const Fivesy = (props) => {
    const secretWord = (props.word || "").toLowerCase();
    const isReachedMaxAttempts = props.prevWords.length === props.maxNumberOfAttempts;

    const [error, setError] = useState("");

    const isDisabled = isReachedMaxAttempts
        || props.isFlipping
        || props.isFinished;

    /* --- */

    const showError = (msg) => {
        setError(msg);

        const secondsInMS = 2 * 1000;

        globalThis.setTimeout(() => {
            setError("");
        }, secondsInMS);
    };

    const isKeysDisabled = () => {
        return error || isDisabled;
    };

    const getPrevWords = () => {
        const res = [];

        for (let i = 0; i < props.prevWords.length; i += 1) {
            const w = props.prevWords[i];
            const wordLetters = utils.newWord(w);
            const letters = utils.compareWordWithLetters(secretWord, wordLetters);

            res.push(letters);
        }

        return res;
    };

    const getKeyboardEnteredLetters = () => {
        const prevWords = props.prevWords || [];
        const letters = utils.wordsToLetters(prevWords);
        const allLetters = utils.compareWordWithAllLetters(secretWord, letters);
        return utils.getUniqueLetters(allLetters);
    };

    const onKeyPress = (letter) => {
        if (isDisabled) {
            return;
        }

        const newWord = `${props.currentWord}${letter}`;

        if (newWord.length <= secretWord.length) {
            props.onLetterChange({
                word: newWord,
            });
        }
    };

    const onDeletePress = () => {
        if (isKeysDisabled() || props.isDisabledKeyboard) {
            return;
        }

        const newWord = props.currentWord.slice(0, -1);

        props.onLetterChange({
            word: newWord,
        });
    };

    const onEnterPress = () => {
        if (isKeysDisabled() || props.isDisabledKeyboard) {
            return;
        }

        if (props.currentWord.length < secretWord.length) {
            showError("Please enter full word");
            return;
        }

        props.onGuess({ word: props.currentWord });
    };

    /* --- */

    const renderMessage = () => {
        if (props.message) {
            return (
                <div className={styles.message}>
                    {props.message}
                </div>
            );
        }

        if (props.error) {
            const errorClassName = classNames({
                [styles.message]: true,
                [styles.messageError]: true,
            });

            return (
                <div className={errorClassName}>
                    {props.error}
                </div>
            );
        }

        let msg = "";

        if (error) {
            msg = error;
        }

        const msgClassName = classNames({
            [styles.message]: true,
            [styles.messageError]: error,
        });

        return (
            <div className={msgClassName}>
                {msg}
            </div>
        );
    };

    const renderBoard = () => {
        return (
            <FivesyBoard
                wordLength={secretWord.length}
                maxNumberOfAttempts={props.maxNumberOfAttempts}
                prevWords={getPrevWords()}
                currWordLetters={utils.newWord(props.currentWord)}
                isFlipping={props.isFlipping}
                isError={error !== ""}
            />
        );
    };

    const renderKeyboard = () => {
        if (props.isFinished || isReachedMaxAttempts) {
            return null;
        }

        return (
            <FivesyKeyboard
                enteredLetters={getKeyboardEnteredLetters()}
                onKey={onKeyPress}
                onEnter={onEnterPress}
                onDelete={onDeletePress}
                isDisabled={props.isDisabledKeyboard || isKeysDisabled()}
            />
        );
    };

    const renderResult = () => {
        if (!props.isFinished && !isReachedMaxAttempts) {
            return null;
        }

        const lastPrevWord = props.prevWords[props.prevWords.length - 1];
        const isSuccess = secretWord === lastPrevWord;

        const message = isSuccess
            ? `Congratulations! You got it in ${props.prevWords.length} tries!`
            : "Better luck next time.";

        return (
            <div className={styles.result}>
                {message}

                <Countdown
                    prefix="Next Fivesy available in"
                    endDate={props.nextDate}
                />
            </div>
        );
    };

    /* --- */

    if (!secretWord) {
        return null;
    }

    return (
        <div className={styles.fivesy}>
            {renderMessage()}

            <div className={styles.fivesyBoard}>
                {renderBoard()}
            </div>

            <div className={styles.fivesyKeyboard}>
                {renderKeyboard()}
                {renderResult()}
            </div>
        </div>
    );
};

Fivesy.defaultProps = {
    word: "",
    currentWord: "",
    maxNumberOfAttempts: 6,
    prevWords: [],
    nextDate: null,
    message: "",
    error: "",
    onLetterChange: () => { },
    onGuess: () => { },
    isFlipping: false,
    isFinished: false,
    isDisabledKeyboard: false,
};

export default Fivesy;
