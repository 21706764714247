import React, { useEffect, useRef } from "react";

import RequestLoader from "@/base/components/request-loader/index.js";

import "./styles.css";
import styles from "./styles.module.css";


const VideoCustom = (props) => {
    // TODO: finish lib global loading
    if (!globalThis.videojs) {
        return (
            <RequestLoader />
        );
    }

    const videoRef = useRef(null); // eslint-disable-line react-hooks/rules-of-hooks
    const playerRef = useRef(null); // eslint-disable-line react-hooks/rules-of-hooks

    const playerOptions = {
        autoplay: false,
        controls: true,
        playsinline: true,

        poster: props.poster,
        sources: [{
            src: props.src,
            type: props.type,
        }],
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (playerRef.current) {
            return;
        }

        if (videoRef?.current && window.videojs) {
            playerRef.current = window.videojs(videoRef.current, playerOptions);
            playerRef.current.addClass("component-video-js");
        }
    }, [videoRef]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [videoRef]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
        if (!props.canPlay && videoRef.current) {
            videoRef.current.pause();
        }
    }, [props.canPlay]);

    return (
        <div
            className={styles.videoPlayer}
            style={{ height: props.videoHeight || "auto" }}
        >
            <div data-vjs-player>
                <video
                    ref={videoRef}
                    className="video-js vjs-big-play-centered"
                    onPlay={() => {
                        props.onPlayStart(props.src, props.videoId);
                    }}
                    onEnded={() => {
                        props.onPlayEnd(props.src, props.videoId);
                    }}
                    onPause={() => {
                        props.onPause(props.src, props.videoId);
                    }}
                    preload="none"
                >
                    <track
                        src={props.captionSrc}
                        kind="captions"
                        srcLang="en"
                        label="English"
                    />
                    <p className="vjs-no-js">
                        To view this video please enable JavaScript,
                        and consider upgrading to a web browser that
                        <a
                            href="https://videojs.com/html5-video-support/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            supports HTML5 video
                        </a>
                    </p>
                </video>
            </div>
        </div>
    );
};

VideoCustom.defaultProps = {
    videoId: -1,
    src: "",
    type: "",
    poster: "",
    videoHeight: "auto",
    canPlay: true,
    withPosterCover: true,
    captionSrc: null,
    onPlayStart: () => { },
    onPlayEnd: () => { },
    onPause: () => { },
};

export default VideoCustom;
