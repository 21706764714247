import React, { useRef } from "react";

import Subscription from "@/base/project/subscription.js";

import date from "@/base/lib/date.js";

import text from "@/base/text/index.js";

import IconEmojiSad from "@/base/icons/emoji-sad/index.js";
import IconStatistic from "@/base/icons/statistic/index.js";
import IconUsers from "@/base/icons/users/index.js";
import IconCalendar from "@/base/icons/calendar/index.js";
import IconCheckInCircle from "@/base/icons/check-in-circle/index.js";
import IconMessageOutlined from "@/base/icons/message-outlined/index.js";

import Checkbox from "@/base/components/forms/checkbox/index.js";
import Radio from "@/base/components/forms/radio/index.js";
import Textarea from "@/base/components/forms/textarea/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import ButtonCircleArrow from "@/base/components/button-circle-arrow/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";

import STEPS from "./steps.js";
import useCancelSubscriptionHook from "./use-cancel-subscription.js";
import styles from "./styles.module.css";


const CancelSubscription = (props) => {
    const withScrollRef = useRef(null);

    /* ------- */

    const getExpirationDate = () => {
        const { expiration } = props.userPlan;

        if (expiration) {
            return date.tryFormatDateUTC(expiration, date.formatMonthDayYearDate);
        }

        return "";
    };

    const getCancelPreferences = () => {
        const dateExpiration = getExpirationDate();

        if (props.userPlan?.hasPlan) {
            return Subscription.getPaidCancelPreferences(dateExpiration);
        }

        return Subscription.getFreeTrialCancelPreferences(dateExpiration);
    };

    /* ------- */

    const onChangeCancelReason = (evt) => {
        const txt = evt?.target?.value || "";
        props.onChangeCancelReason(txt);
    };

    const scrollToTop = () => {
        if (withScrollRef?.current?.scrollTo) {
            withScrollRef.current.scrollTo(0, 0);
        }
    };

    const onChangeStep = (step) => {
        props.onChangeStep(step);
        scrollToTop();
    };

    /* ------- */

    const renderMenu = () => {
        const menu = [
            text.tooManyTechnicalIssues,
            text.productDoesNotWorkAsExpected,
            text.tooComplicated,
            text.productForASpecificDuration,
            text.notUsingEnough,
            text.contentDidNotLiveUpToExpectations,
        ].map((opt, i) => {
            return (
                <div className={styles.menuOption}>
                    <Checkbox
                        name={`menu-option-${i}`}
                        label={opt}
                        onChange={() => {
                            props.onToggleReasonOption(opt);
                        }}
                        checked={props.selectedReasonOptions.indexOf(opt) !== -1}
                    />
                </div>
            );
        });

        return (
            <div className={styles.menu}>
                {menu}
            </div>
        );
    };

    const renderCancelOptions = () => {
        const menu = getCancelPreferences();

        const menuOptions = menu.map((opt) => {
            return (
                <div className={styles.menuOption}>
                    <Radio
                        name={`cancel-option-${opt.value}`}
                        label={opt.label}
                        onChange={() => {
                            props.onSelectCancelPreference(opt.value);
                        }}
                        checked={props.cancelPreference === opt.value}
                    />
                </div>
            );
        });

        return (
            <div className={styles.menu}>
                {menuOptions}
            </div>
        );
    };

    const renderTextarea = () => {
        return (
            <Textarea
                name="cancel-reason"
                placeholder={text.additionalReason}
                value={props.cancelReason}
                maxLength={280}
                onChange={onChangeCancelReason}
                showMaxLengthMessage
                isMini
            />
        );
    };

    const renderEmoji = () => {
        return (
            <div className={styles.emojiContainer}>
                <IconEmojiSad
                    className={styles.emoji}
                />
                <div>
                    {text.weAreSorryToSeeYouGo}
                </div>
            </div>
        );
    };

    const renderCancelReason = () => {
        const isDisabled = props.selectedReasonOptions.length === 0
            && !props.cancelReason;

        return (
            <>
                {renderEmoji()}
                <div className={styles.contentCancelReason}>
                    <div className={styles.description}>
                        <div>
                            {text.whyYouWantToCancel}
                        </div>
                        <div className={styles.secondaryText}>
                            {text.selectThatApply}
                        </div>
                    </div>
                    {renderMenu()}
                    {renderTextarea()}
                </div>
                <div className={styles.controls}>
                    <ButtonBig
                        onClick={() => {
                            onChangeStep(STEPS.cancellationOptions);
                        }}
                        disabled={isDisabled}
                        isRounded
                    >
                        Continue
                    </ButtonBig>
                </div>
            </>
        );
    };

    const renderCancellationPreference = () => {
        return (
            <>
                {renderEmoji()}
                <div className={styles.contentCancelOptions}>
                    <div className={styles.description}>
                        <div>
                            {text.cancelPreference}
                        </div>
                        <div className={styles.secondaryText}>
                            {text.selectOptionBelow}
                        </div>
                    </div>

                    {renderCancelOptions()}
                </div>
                <div className={styles.controlsWithBack}>
                    <ButtonCircleArrow
                        onClick={() => {
                            onChangeStep(STEPS.cancellationReason);
                        }}
                        left
                    />
                    <ButtonBig
                        onClick={() => {
                            onChangeStep(STEPS.confirmCancellation);
                        }}
                        isRounded
                    >
                        Continue
                    </ButtonBig>
                </div>
            </>
        );
    };

    const renderCancellationDetails = () => {
        const dateExpiration = getExpirationDate();
        const { cancelPreference } = props;

        let cancellationDate = `Access to your plan will end on ${dateExpiration}.`;
        let accounts = `You will no longer have access to your teacher/administrator account and your students will be unable to access their accounts after ${dateExpiration}`;

        if (Subscription.isCancelImmediately(cancelPreference)) {
            cancellationDate = text.accessEndImmediately;
            accounts = text.noLongerAccess;
        }

        let dailyEmails = text.noLongerDailyEmails;

        if (Subscription.isPaidCancelAndKeepEmails(cancelPreference)) {
            dailyEmails = `You will continue receiving daily emails from The Juice until ${dateExpiration}`;
        }

        const rows = [
            {
                icon: IconCalendar,
                title: text.cancellationDate,
                description: cancellationDate,
            },
            {
                icon: IconMessageOutlined,
                title: text.dailyEmails,
                description: dailyEmails,
            },
            {
                icon: IconUsers,
                title: text.accounts,
                description: accounts,
            },
            {
                icon: IconStatistic,
                title: text.data,
                description: text.loseAccessAndData,
            },
        ];

        const detailsRows = rows.map((row) => {
            return (
                <div className={styles.cancellationDetailsRow}>
                    <row.icon
                        className={styles.cancellationDetailsRowIcon}
                        isSky
                    />
                    <div className={styles.cancellationDetailsRowInfo}>
                        <div className={styles.cancellationDetailsRowInfoTitle}>
                            {row.title}
                        </div>
                        <div className={styles.secondaryText}>
                            {row.description}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.cancellationDetails}>
                <div className={styles.secondaryText}>
                    {text.cancellationDetails}
                </div>
                <div className={styles.cancellationDetailsRows}>
                    {detailsRows}
                </div>
            </div>
        );
    };

    const renderConfirmCancellation = () => {
        const userPlan = Subscription.getPlanByPlanId(props.plans.plans, props.userPlan.planId);

        let userPlanName = "";

        if (!props.userPlan?.hasPlan) {
            userPlanName = text.freeTrial;
        } else if (props.plans.isPlansLoaded && userPlan?.name) {
            userPlanName = `${userPlan.name} Plan`;
        }

        let description = null;

        if (userPlanName) {
            description = (
                <div className={styles.confirmCancellationDescription}>
                    <div>
                        {text.affectedPlan}
                    </div>
                    <div className={styles.secondaryText}>
                        {userPlanName}
                    </div>
                </div>
            );
        }

        let cancelButtonText = text.cancelSubscription;

        if (props.isCancelling) {
            cancelButtonText = text.inProcess;
        } else if (props.error) {
            cancelButtonText = text.tryAgain;
        }

        return (
            <>
                {renderEmoji()}
                <div className={styles.contentConfirmCancellation}>
                    {description}
                    {renderCancellationDetails()}
                </div>
                <div className={styles.controlsWithBack}>
                    <ButtonCircleArrow
                        onClick={() => {
                            onChangeStep(STEPS.cancellationOptions);
                        }}
                        disabled={props.isCancelling}
                        left
                    />
                    <div className={styles.controlsWithError}>
                        <ButtonBig
                            onClick={props.onCancelSubscription}
                            disabled={props.isCancelling}
                            isRoseTheme
                            isRounded
                        >
                            {cancelButtonText}
                        </ButtonBig>
                        <div className={styles.error}>
                            {props.error}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderCancellationCompleted = () => {
        const dateExpiration = getExpirationDate();

        const planCanceledText = props.userPlan?.hasPlan
            ? text.planCanceled
            : text.freeTrialCanceled;

        let subscriptionDetails = null;

        if (!Subscription.isCancelImmediately(props.cancelPreference)) {
            subscriptionDetails = (
                <div className={styles.cancellationCompletedBlock}>
                    {`You can still access your subscription until ${dateExpiration}`}
                </div>
            );
        }

        return (
            <div className={styles.cancellationCompleted}>
                <div className={styles.cancellationCompletedInfo}>
                    <div className={styles.cancellationCompletedBlock}>
                        <IconCheckInCircle
                            className={styles.iconCheck}
                            isGreen
                        />
                        <div className={styles.cancellationCompletedBlockTitle}>
                            {planCanceledText}
                        </div>
                    </div>
                    {subscriptionDetails}
                </div>
                <div className={styles.controls}>
                    <ButtonBig
                        onClick={props.onClose}
                        outlined
                        isRounded
                    >
                        {text.close}
                    </ButtonBig>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (props.step === STEPS.cancellationReason) {
            return renderCancelReason();
        }

        if (props.step === STEPS.cancellationOptions) {
            return renderCancellationPreference();
        }

        if (props.step === STEPS.confirmCancellation) {
            return renderConfirmCancellation();
        }

        if (props.step === STEPS.cancellationCompleted) {
            return renderCancellationCompleted();
        }

        return null;
    };

    return (
        <WithScroll scrollRef={withScrollRef}>
            {renderContent()}
        </WithScroll>
    );
};

CancelSubscription.defaultProps = {
    step: "",
    cancelReason: "",
    error: "",
    userPlan: {},
    plans: {},
    cancelPreference: "",
    selectedReasonOptions: [],
    onChangeStep: () => { },
    onSelectCancelPreference: () => { },
    onToggleReasonOption: () => { },
    onCancelSubscription: () => { },
    onChangeCancelReason: () => { },
    onClose: () => { },
    isCancelling: false,
};

export const useCancelSubscription = useCancelSubscriptionHook;
export default CancelSubscription;
