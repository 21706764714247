import React, { useEffect } from "react";

import utils from "./utils.js";

import FivesyKeyboardKey from "./keyboard-key.js";

import styles from "./styles.module.css";


const FivesyKeyboard = (props) => {
    const row1 = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"];
    const row2 = ["a", "s", "d", "f", "g", "h", "j", "k", "l"];
    const row3 = ["z", "x", "c", "v", "b", "n", "m"];

    const allKeys = [...row1, ...row2, ...row3];

    /* --- */

    useEffect(() => {
        const listener = (evt) => {
            evt.stopPropagation();

            if (evt.code === "Enter") {
                props.onEnter();
            } else if (evt.code === "Backspace") {
                props.onDelete();
            } else {
                const key = evt.key.toLowerCase();

                if (key.length === 1 && allKeys.indexOf(key) !== -1) {
                    props.onKey(key);
                }
            }
        };

        globalThis.addEventListener("keyup", listener);

        return () => {
            globalThis.removeEventListener("keyup", listener);
        };
    }, [props.onKey, props.onEnter, props.onDelete]);

    /* --- */

    const renderKeys = (keys) => {
        return keys.map((key) => {
            const ltr = utils.getLetterByValue(props.enteredLetters, key);

            if (ltr) {
                return (
                    <FivesyKeyboardKey
                        value={key}
                        onClick={() => {
                            props.onKey(key);
                        }}
                        isAbsent={!ltr.isPresent && !ltr.isCorrect}
                        isPresent={ltr.isPresent}
                        isCorrect={ltr.isCorrect}
                        isDisabled={props.isDisabled}
                    />
                );
            }

            return (
                <FivesyKeyboardKey
                    value={key}
                    onClick={() => {
                        props.onKey(key);
                    }}
                    isDisabled={props.isDisabled}
                />
            );
        });
    };

    const renderRow = (row) => {
        return (
            <div className={styles.keyboardRow}>
                {renderKeys(row)}
            </div>
        );
    };

    const renderRowWithControl = (row) => {
        return (
            <div className={styles.keyboardRow}>
                <FivesyKeyboardKey
                    value="Enter"
                    onClick={() => {
                        props.onEnter();
                    }}
                    isControl
                    isDesktop
                    isDisabled={props.isDisabled}
                />

                {renderKeys(row)}

                <FivesyKeyboardKey
                    value="Delete"
                    onClick={() => {
                        props.onDelete();
                    }}
                    isControl
                    isDesktop
                    isDisabled={props.isDisabled}
                />
            </div>
        );
    };

    const renderRowWithMobileControl = () => {
        const classes = [
            styles.keyboardRow,
            styles.keyboardRowMobile,
        ].join(" ");

        return (
            <div className={classes}>
                <FivesyKeyboardKey
                    value="Enter"
                    onClick={() => {
                        props.onEnter();
                    }}
                    isControl
                    isDisabled={props.isDisabled}
                />

                <FivesyKeyboardKey
                    value="Delete"
                    onClick={() => {
                        props.onDelete();
                    }}
                    isControl
                    isDisabled={props.isDisabled}
                />
            </div>
        );
    };

    return (
        <div className={styles.keyboard}>
            {renderRow(row1)}
            {renderRow(row2)}
            {renderRowWithControl(row3)}
            {renderRowWithMobileControl()}
        </div>
    );
};

FivesyKeyboard.defaultProps = {
    enteredLetters: [],
    onKey: () => { },
    onEnter: () => { },
    onDelete: () => { },
    isDisabled: false,
};

export default FivesyKeyboard;
