import React, { useMemo } from "react";

import classNames from "@/base/lib/class-names.js";

import RateLike from "@/base/project/rate-like.js";

import IconLike from "@/base/icons/like/index.js";
import IconLikeEmpty from "@/base/icons/like-empty/index.js";
import IconDislikeEmpty from "@/base/icons/dislike-empty/index.js";
import IconDislike from "@/base/icons/dislike/index.js";

import ButtonIcon from "@/base/components/button-icon/index.js";

import styles from "./styles.module.css";


const Rate = (props) => {
    const storyRate = useMemo(() => ({
        isLiked: RateLike.isStoryRateLiked(props.rate),
        isDisliked: RateLike.isStoryRateDisliked(props.rate),
    }), [props.rate]);

    /* --- */

    const onLike = () => {
        if (storyRate.isLiked) {
            return;
        }

        const rating = RateLike.getLikeValue();
        props.onRate(rating);
    };

    const onDislike = () => {
        if (storyRate.isDisliked) {
            return;
        }

        const rating = RateLike.getDislikeValue();
        props.onRate(rating);
    };

    /* --- */

    const containerClassName = classNames({
        [styles.container]: true,
        [props.className]: props.className,
    });

    return (
        <div className={containerClassName}>
            <div className={styles.title}>
                Rate this story
            </div>
            <ButtonIcon
                icon={<IconLikeEmpty isBlack />}
                iconPressed={<IconLike isGreen />}
                onClick={onLike}
                isPressed={storyRate.isLiked}
            />
            <ButtonIcon
                icon={<IconDislikeEmpty isBlack />}
                iconPressed={<IconDislike isRed />}
                onClick={onDislike}
                isPressed={storyRate.isDisliked}
            />
        </div>
    );
};

Rate.defaultProps = {
    className: "",
    rate: null,
    onRate: () => { },
};

export default Rate;
