export const DATES = {
    today: {
        label: "Today",
        value: "today",
    },
    yesterday: {
        label: "Yesterday",
        value: "yesterday",
    },
    oneWeek: {
        label: "1 Week",
        value: "7-days",
    },
    twoWeeks: {
        label: "2 Week",
        value: "14-days",
    },
    thisWeek: {
        label: "This Week",
        value: "this-week",
    },
    lastWeek: {
        label: "Last Week",
        value: "last-week",
    },
    oneMonth: {
        label: "1 Month",
        value: "30-days",
    },
    threeMonths: {
        label: "3 Months",
        value: "90-days",
    },
    thisMonth: {
        label: "This Month",
        value: "this-month",
    },
    past3Months: {
        label: "Past 3 Months",
        value: "past-3-months",
    },
};

export const DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const SHORT_DAYS = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const SHORT_MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

/* --- */

const nth = (d) => {
    if (d > 3 && d < 21) {
        return "th";
    }

    switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
};

export const newDate = (dateStr) => {
    if (!dateStr) {
        return null;
    }

    const date = new Date(dateStr);

    if (date.toString() !== "Invalid Date") {
        return date;
    }

    const date2 = new Date(dateStr.replace(/-/g, "/"));

    if (date2.toString() !== "Invalid Date") {
        return date2;
    }

    return null;
};

export const newDateUTC = (dateStr) => {
    const d = newDate(dateStr);

    if (!d) {
        return null;
    }

    return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
};

/* --- */

const getDiffSeconds = (dateStart, dateEnd) => {
    const time = Math.abs(dateEnd.getTime() - dateStart.getTime());
    return Math.round(time / 1000);
};

const getDiffHours = (dateStart, dateEnd) => {
    const time = dateEnd - dateStart;
    return Math.floor(time / 1000 / 60 / 60);
};

export const getDiffInDays = (start, end) => {
    const d1 = newDate(start);
    const d2 = newDate(end);

    const oneDay = 1000 * 60 * 60 * 24;

    const diffInTime = d2.getTime() - d1.getTime();
    return Math.round(diffInTime / oneDay);
};

const secondsToValues = (valueInSeconds) => {
    const seconds = valueInSeconds % 60;
    const allMinutes = (valueInSeconds - seconds) / 60;
    const minutes = allMinutes % 60;
    const hours = (allMinutes - minutes) / 60;

    return {
        seconds,
        minutes,
        hours,
    };
};

/* --- */

const formatTime = (d) => {
    let hours = d.getHours();
    const minutes = d.getMinutes();

    const minStr = minutes > 9
        ? minutes
        : `0${minutes}`;

    if (hours > 12) {
        hours -= 12;
        return `${hours}:${minStr} PM`;
    }

    return `${hours}:${minStr} AM`;
};

export const formatDay = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();

    return `${DAYS[dayOfWeek]}`;
};

export const formatDayDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const day = date.getDate();

    return `${DAYS[dayOfWeek]}, ${MONTHS[month]} ${day}`;
};

const formatDayDateWithTime = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const day = date.getDate();

    const time = formatTime(date);

    return `${DAYS[dayOfWeek]}, ${MONTHS[month]} ${day} at ${time}`;
};

export const formatDayShortMonthDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const day = date.getDate();

    return `${SHORT_DAYS[dayOfWeek]}, ${SHORT_MONTHS[month]} ${day}`;
};

export const formatShortMonthDate = (date) => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();
    const day = date.getDate();

    return `${SHORT_MONTHS[month]} ${day}`;
};

export const formatShortMonthDayYearDate = (date) => {
    if (!date) {
        return "";
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${SHORT_MONTHS[month]} ${day} ${year}`;
};

export const formatFullDayShortMonthDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const day = date.getDate();

    return `${DAYS[dayOfWeek]}, ${SHORT_MONTHS[month]} ${day}`;
};

export const formatDayMonthYearDate = (date) => {
    if (!date) {
        return "";
    }

    const dayOfWeek = date.getDay();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${DAYS[dayOfWeek]}, ${MONTHS[month]} ${day}, ${year}`;
};

export const formatMonthDayYearDate = (date) => {
    if (!date) {
        return "";
    }

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${MONTHS[month]} ${day}, ${year}`;
};

export const formatMonthDayDate = (date, withEnding = true) => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();
    let day = date.getDate();

    if (withEnding) {
        day = `${day}${nth(day)}`;
    }

    return `${MONTHS[month]} ${day}`;
};

export const formatFullMonthDayDate = (date) => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();
    const day = date.getDate();

    return `${MONTHS[month]} ${day}`;
};

export const formatMonthYearDate = (date, separator = ",") => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();
    const year = date.getFullYear();

    return `${MONTHS[month]}${separator} ${year}`;
};

export const formatMonthDayYear = (date) => {
    if (!date) {
        return "";
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
};

export const tryFormatDate = (dateStr, formatFn) => {
    const date = newDate(dateStr);

    if (!date) {
        return "";
    }

    return formatFn(date);
};

export const tryFormatDateUTC = (dateStr, formatFn) => {
    const date = newDateUTC(dateStr);

    if (!date) {
        return "";
    }

    return formatFn(date);
};

// TODO: rename to format
export const getDateFromDate = (date, separator = "-") => {
    let today = newDate(date);

    if (!today) {
        return "";
    }

    const dd = String(today.getDate()).padStart(2, "0"); // eslint-disable-line compat/compat
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // eslint-disable-line compat/compat
    const yyyy = today.getFullYear();

    today = `${yyyy}${separator}${mm}${separator}${dd}`;

    return today;
};

export const getYearMonthFromDate = (date) => {
    const today = newDate(date);

    if (!today) {
        return "";
    }

    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    if (month < 10) {
        return `${year}-0${month}`;
    }

    return `${year}-${month}`;
};

export const getMonthDayFromDate = (date) => {
    const today = newDate(date);

    if (!today) {
        return "";
    }

    const month = today.getMonth() + 1;
    const day = today.getDate();

    if (month < 10) {
        return `0${month}/${day}`;
    }

    return `${month}/${day}`;
};

/* --- */

const stringToDayDate = (date) => {
    return (date || "").split(" ")[0] || "";
};

export const isToday = (date) => {
    const now = new Date();

    return now.getDate() === date.getDate()
        && now.getMonth() === date.getMonth()
        && now.getYear() === date.getYear();
};

export const getStartOfMonthDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getNextMonth = (date) => {
    const now = newDate(date);

    if (now.getMonth() === 11) {
        return new Date(now.getFullYear() + 1, 0, 1);
    }

    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
};

export const getPrevMonth = (date) => {
    const now = newDate(date);

    if (now.getMonth() === 0) {
        return new Date(now.getFullYear() - 1, 11, 1);
    }

    return new Date(now.getFullYear(), now.getMonth() - 1, 1);
};

export const getDaysInMonth = (date) => {
    const d = newDate(date);

    return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
};

export const getShortMonth = (date) => {
    if (!date) {
        return "";
    }

    const month = date.getMonth();

    return `${SHORT_MONTHS[month]}`;
};

export const getMonday = (d) => {
    const date = newDate(d);
    const day = date.getDay();

    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return newDate(newDate(d).setDate(diff));
};

export const getFriday = (d) => {
    const date = newDate(d);
    const day = date.getDay();

    const diff = date.getDate() - day + (day === 0 ? -2 : 5);

    return newDate(newDate(d).setDate(diff));
};

export const getSunday = (d) => {
    const date = newDate(d);
    const day = date.getDay();

    const diff = date.getDate() - day + (day === 0 ? -7 : 0);

    return newDate(newDate(d).setDate(diff));
};

export const getDatesByRange = (date, range) => {
    let dateFrom = newDate(date);
    let dateTo = newDate(date);

    if (range === DATES.yesterday.value) {
        dateFrom.setDate(dateFrom.getDate() - 1);
        dateTo.setDate(dateTo.getDate() - 1);
    } else if (range === DATES.thisWeek.value) {
        dateFrom = getMonday(dateFrom);
    } else if (range === DATES.lastWeek.value) {
        dateFrom = getMonday(dateFrom);
        dateFrom.setDate(dateFrom.getDate() - 7);
        dateTo = newDate(dateFrom);
        dateTo.setDate(dateTo.getDate() + 6);
    } else if (range === DATES.thisMonth.value) {
        dateFrom = newDate(getStartOfMonthDate(dateFrom));
    } else if (range === DATES.past3Months.value) {
        dateFrom.setMonth(dateTo.getMonth() - 3);
    } else if (range === DATES.oneWeek.value) {
        dateFrom.setDate(dateFrom.getDate() - 7);
    } else if (range === DATES.twoWeeks.value) {
        dateFrom.setDate(dateFrom.getDate() - 14);
    } else if (range === DATES.oneMonth.value) {
        dateFrom.setDate(dateFrom.getDate() - 30);
    } else if (range === DATES.threeMonths.value) {
        dateFrom.setDate(dateFrom.getDate() - 90);
    } else if (range === "all") {
        dateFrom = "all";
        dateTo = "";
    }

    return {
        dateFrom,
        dateTo,
    };
};

export const getFormattedRangeByRange = (date, range) => {
    const { dateFrom, dateTo } = getDatesByRange(date, range);

    if (range === DATES.today.value || range === DATES.yesterday.value) {
        return formatMonthDayYearDate(dateTo);
    }

    if (range === DATES.thisWeek.value || range === DATES.lastWeek.value) {
        return `${formatMonthDayDate(dateFrom, false)} - ${formatMonthDayYearDate(dateTo)}`;
    }

    if (range === DATES.thisMonth.value) {
        return formatMonthYearDate(dateFrom);
    }

    return `${formatMonthDayYearDate(dateFrom)} - ${formatMonthDayYearDate(dateTo)}`;
};

export const getRanges = () => {
    return [
        DATES.today,
        DATES.yesterday,
        DATES.thisWeek,
        DATES.lastWeek,
        DATES.thisMonth,
        DATES.past3Months,
    ];
};

export const getRangesMini = () => {
    return [
        DATES.thisWeek,
        DATES.thisMonth,
        DATES.past3Months,
    ];
};

const getRangeOptions = (currentDate, selectedDateFrom, selectedDateTo) => {
    const ranges = getRanges();
    const options = [];

    let selected = "custom";

    for (let i = 0; i < ranges.length; i += 1) {
        const range = ranges[i];

        const dates = getDatesByRange(currentDate, range.value);
        const dateFrom = getDateFromDate(dates.dateFrom);
        const dateTo = getDateFromDate(dates.dateTo);

        if (dateFrom === selectedDateFrom && dateTo === selectedDateTo) {
            selected = range.value;
        }

        options.push({
            value: range.value,
            label: range.label,
            label2: getFormattedRangeByRange(currentDate, range.value),
            dateFrom,
            dateTo,
        });
    }

    let customLabel2 = "";

    if (selected === "custom") {
        const dFrom = formatMonthDayYearDate(newDateUTC(selectedDateFrom));
        const dTo = formatMonthDayYearDate(newDateUTC(selectedDateTo));

        if (selectedDateFrom === selectedDateTo) {
            customLabel2 = dFrom;
        } else {
            customLabel2 = `${dFrom} - ${dTo}`;
        }
    }

    options.push({
        value: "custom",
        label: "Custom",
        label2: customLabel2,
    });

    return {
        options,
        selected,
    };
};

export const getLabelByRange = (range) => {
    let rangeLabel = DATES.today.label;

    const allKeys = Object.keys(DATES);

    for (let i = 0; i < allKeys.length; i += 1) {
        if (DATES[allKeys[i]].value === range) {
            rangeLabel = DATES[allKeys[i]].label;
            break;
        }
    }

    return rangeLabel;
};

const addDays = (date, days) => {
    const d = newDate(date);

    if (!d) {
        return null;
    }

    d.setDate(d.getDate() + days);

    return d;
};

const addYears = (date, years) => {
    const d = newDate(date);

    if (!d) {
        return null;
    }

    d.setFullYear(d.getFullYear() + years);
    return new Date(d);
};

export const getMondayFridayByDate = (d) => {
    const monday = getMonday(d);
    const friday = addDays(monday, 4);

    return {
        monday,
        friday,
    };
};

const getNextDay = (date) => {
    const d = new Date(date.getTime());

    d.setDate(d.getDate() + 1);

    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);

    return d;
};

const getDateNowWithOffset = (dateStart, dateEnd) => {
    const diff = new Date().getTime() + (dateEnd - dateStart);
    return new Date(diff);
};

export const getWeekDaysByDate = (d) => {
    const monday = getMonday(d);

    const weekdays = [];

    for (let i = 0; i < 5; i += 1) {
        const nextDate = addDays(monday, i);

        weekdays.push(nextDate);
    }

    return weekdays;
};

export const getPrevWeekDaysByDate = (d) => {
    const prevDate = addDays(d, -7);

    return getWeekDaysByDate(prevDate);
};

export const getNextWeekDaysByDate = (d) => {
    const nextDate = addDays(d, 7);

    return getWeekDaysByDate(nextDate);
};

export const getWeekShortDays = () => {
    return [
        { value: "monday", label: "M" },
        { value: "tuesday", label: "T" },
        { value: "wednesday", label: "W" },
        { value: "thursday", label: "T" },
        { value: "friday", label: "F" },
        { value: "saturday", label: "S" },
        { value: "sunday", label: "S" },
    ];
};

const isDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toString() !== "Invalid Date";
};

export const isDatesSame = (date1, date2) => {
    if (!date1 || !date2) {
        return false;
    }

    const d1 = newDate(getDateFromDate(date1));
    const d2 = newDate(getDateFromDate(date2));

    return d1.getTime() === d2.getTime();
};

export const isWeekend = (date) => {
    const day = newDate(date).getDay();

    if (day === 0 || day === 6) {
        return true;
    }

    return false;
};

const isDateInRange = (d, dateFrom, dateTo) => {
    const date = newDate(d);

    const start = newDate(dateFrom);
    const end = newDate(dateTo);

    if (date > start && date < end) {
        return true;
    }

    if (isDatesSame(date, start)) {
        return true;
    }

    if (isDatesSame(date, end)) {
        return true;
    }

    return false;
};

export default {
    DATES,

    newDate,
    newDateUTC,

    getDiffSeconds,
    getDiffHours,
    getDiffInDays,
    secondsToValues,

    tryFormatDate,
    tryFormatDateUTC,

    formatTime,
    formatDayDate,
    formatDayDateWithTime,
    formatDayShortMonthDate,
    formatFullDayShortMonthDate,
    formatShortMonthDayYearDate,
    formatShortMonthDate,
    formatMonthYearDate,
    formatMonthDayYearDate,
    formatMonthDayYear,
    formatFullMonthDayDate,
    formatDay,
    getDateFromDate,
    getYearMonthFromDate,
    getMonthDayFromDate,

    stringToDayDate,

    getDatesByRange,
    getRanges,
    getRangesMini,
    getRangeOptions,

    getLabelByRange,

    getFormattedRangeByRange,
    getNextDay,
    getDateNowWithOffset,
    getWeekDaysByDate,
    getPrevWeekDaysByDate,
    getNextWeekDaysByDate,
    getMondayFridayByDate,
    getStartOfMonthDate,
    getMonday,
    getSunday,
    getFriday,
    getShortMonth,
    getNextMonth,
    getPrevMonth,
    getDaysInMonth,
    getWeekShortDays,

    addYears,
    addDays,

    isDate,
    isDatesSame,
    isWeekend,
    isToday,
    isDateInRange,
};
